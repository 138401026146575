import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import parse from 'html-react-parser';
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container";
import { SectionA, styles} from "../components/styles";
import SectionHeader from "../components/section-header";
import Table from "../components/tables";

const Image = styled.img`
  height: 400px;
  width: 100%;
  z-index: 0;
  object-fit: contain;
`

const Date = styled.span`
  color: #fff;
  z-index: 1;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: .1em;
  font-feature-settings: 'pnum' on, 'lnum' on, 'kern' off;
  margin: 0;
  background-color: ${styles.colorSecondary};
  padding: 5px;
`

const Content = styled.div`
  padding: 15px 0;
  max-width: 1200px;

  h4, h5 {
    padding: 35px 0 15px;
    margin: 0;
  }

  ul {
    list-style: none;
    padding-left: 5px;
    padding-bottom: 15px;

    li {
      font-size: 16px;
      margin: 0;
      padding-bottom: 10px;
    }
  }

  a {
    color: ${styles.colorSecondary};
    text-decoration: none;
  }

  p {
    margin-bottom: 10px;
    font-size: 16px;
  }

  span, strong {
    display: contents;
    font-weight: 900;
  }

  table {
    max-width: 100%;
    border: 1px solid ${styles.colorLightGray};

    tr {
      display: flex;
      flex-direction: column;

      td {
        width: 100% !important;
        font-size: 16px;

        a {
          display: contents;
        }
      }
    }

    @media (min-width: 768px) {
      tr {
        flex-direction: row;

        td {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
  }
`;

const PageLink = styled(Link)`
  color: ${styles.colorSecondary};
  text-decoration: none;
`;

class Page extends Component {
  render() {
    const page = this.props.data.wordpressPage
    const title = parse(page.title);
    const content = parse(page.content);
    const tables = this.props.data.allWordpressAcfPages;
    const pageBody = tableCheck(title, tables, content);

    return (
      <Layout>
        <SectionA p={"141px 0 30px"} pAM={"183px 0 30px"} pXL={"225px 0 30px"}>
          <Container>
            <SEO title={title} />
            <h1>{title}</h1>
            <>{pageBody}</>
            <PageLink to="/">Powrót do strony głównej</PageLink>
          </Container>
        </SectionA>
      </Layout>
    )
  }
}

export default Page

export const postQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
    }
    site {
      siteMetadata {
        title
      }
    }

    allWordpressAcfPages(filter: {wordpress_id: {eq: 45}}) {
      nodes {
        acf {
          tabela1_tabela_glowna
          tabela1_tytul
          tabela1_tabela_regionalne
          tabela2_tabela_glowna
          tabela2_tytul
          tabela2_tabela_regionalne
          tabela3_tabela_glowna
          tabela3_tytul
          tabela3_tabela_regionalne
        }
      }
    }
  }
`

const tableCheck = (title, tables, content) => {
  if (title === "Terminarze zawodów") {
    return (
      <>
        {tables.nodes.map(item => (
            <Table title={item.acf.tabela1_tytul} content={item.acf.tabela1_tabela_glowna} subcontent={item.acf.tabela1_tabela_regionalne}/>
        ))}
        {tables.nodes.map(item => (
            <Table title={item.acf.tabela2_tytul} content={item.acf.tabela2_tabela_glowna} subcontent={item.acf.tabela2_tabela_regionalne} />
        ))}
        {tables.nodes.map(item => (
            <Table title={item.acf.tabela3_tytul} content={item.acf.tabela3_tabela_glowna} subcontent={item.acf.tabela3_tabela_regionalne} />
        ))}
      </>
    )
  } else {
    return <Content>{content}</Content>
  }
}